import React, { Component } from 'react'

import Navigation from './components/navigation';
import Slider from './components/slider';
import Header from './components/header';
import Footer from './components/footer';
import About from './components/about';
import Services from './components/services';
import Gallery from './components/gallery';
import Team from './components/Team';
import Factory1 from './components/factory1';
import Factory2 from './components/factory2';
import Factory3 from './components/factory3';
import Factory4 from './components/factory4';
import Works from './components/works';
import Contact from './components/contact';
import JsonData from './data/data.json';

export class App extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        <Header data={this.state.landingPageData.Header} />
        
        <Navigation />
        
        <div class="clearfix;"></div>
        <Slider />
       
        <About data={this.state.landingPageData.About} />
        <div class="clearfix;"></div>
        <div class="clearfix;"></div>
        <Works />
        <div class="clearfix;"></div>
       

        {/*<Factory1 />
        <Factory2 />
        <Factory3 />
        <Factory4 />*/}
        
        <Gallery />
        <div class="clearfix;"></div>
        <Services data={this.state.landingPageData.Services} />
        <Team data={this.state.landingPageData.Team} />
        <Contact data={this.state.landingPageData.Contact} />
        <div class="clearfix;"></div>
        <Footer />
        </div>
    )
  }

  /*render() {
    return (
      <div>
        <Navigation />
        <Header data={this.state.landingPageData.Header} />
        <Features data={this.state.landingPageData.Features} />
        <About data={this.state.landingPageData.About} />
        <Services data={this.state.landingPageData.Services} />
        <Gallery />
        <Testimonials data={this.state.landingPageData.Testimonials} />
        <Team data={this.state.landingPageData.Team} />
        <Contact data={this.state.landingPageData.Contact} />
      </div>
    )
  }*/
}

export default App;
