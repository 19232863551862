import React, { Component } from "react";

export class Factory3 extends Component {
  render() {
    return (
        <div id="factory3">
    <div class="master-container fachigh">
            <div class="container">
            
                
                 <div class="row">
                    
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/ironing.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac3-2">
                                <h1 itemprop="name" class="product_title entry-title">Ironing</h1>
                                <div itemprop="description">
                                    <p>Stream Ironing the Finished products to packing quickly. Garments are steam processed to remove any wrinkles.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/unnamed (1).jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac2-1">
                                <h1 itemprop="name" class="product_title entry-title">Packing</h1>
                                <div itemprop="description">
                                    <p>As per customers instruction garments are neatly packing final for shipment. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </div>
        );
  }
}

export default Factory3;