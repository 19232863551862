import React, { Component } from "react";

export class Navigation extends Component {
  render() {
    return (
      <nav id="menu" className="navbar navbar-default ">
         <a id="back2Top" title="Back to top" href="#">&#10148;</a>
        <div className="">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            
          </div>

          <div
            className="collapse navbar-collapse navig"
            id="bs-example-navbar-collapse-1">
            <ul className="nav navbar-nav ">
              <li>
                
                <a href="#" className="page-scroll faa-parent animated-hover">
                <i class="fa fa-home faa-wrench animated-hover" aria-hidden="true"></i> &nbsp;
                  HOME
                </a>
              </li>
              
              <li class="dropdown">
                <a href="#gallerypage" className="page-scroll faa-parent animated-hover">
                <i class="fa fa-angle-double-down faa-wrench animated-hover" aria-hidden="true"></i> &nbsp;
                  GALLERY
                </a>
                <div class="dropdown-content">
  <a href="#men" class=" esg-filterbutton esg-filter-wrapper  esg-fgc-2 " data-filter="men">MEN</a>
  <a href="#women" class=" esg-filterbutton esg-filter-wrapper  esg-fgc-2" data-filter="women">Women</a>
  <a href="#boys" class=" esg-filterbutton esg-filter-wrapper  esg-fgc-2" data-filter="boys">BOYS</a>
  <a href="#girls" class=" esg-filterbutton esg-filter-wrapper  esg-fgc-2" data-filter="girls">GIRLS </a>
  </div>
              </li>
              
              <li>
                
                <a href="#about" className="page-scroll faa-parent animated-hover">
                <i class="fa fa-users faa-wrench animated-hover" aria-hidden="true"></i> &nbsp;
                ABOUT US
                </a>
              </li>
              <li class="dropdown">
                <a href="#works" className="page-scroll faa-parent animated-hover">
                <i class="fa fa fa-university faa-wrench animated-hover" aria-hidden="true"></i> &nbsp;
                 FACTORY
                </a>
                
              </li>
              <li>
              
                <a href="#services" className="page-scroll faa-parent animated-hover">
                <i class="fa fa-trophy faa-wrench animated-hover" aria-hidden="true"></i> &nbsp;
                  SERVICES
                </a>
              </li>
              <li>
                <a href="#team" className="page-scroll faa-parent animated-hover">
                <i class="fa fa-check-square-o faa-wrench animated-hover" aria-hidden="true"></i> &nbsp;
                  CODE OF CONDUCT
                </a>
              </li>

              <li>
              
                <a href="#contact" className="page-scroll faa-parent animated-hover">
                <i class="fa fa-envelope faa-wrench animated-hover" aria-hidden="true"></i> &nbsp;
                 CONTACT US
                </a>
              </li>
              
            </ul>
           
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
