import React, { Component } from "react";
import './foot.css'; 
import AnalogClock from 'r-analog-clock'


export class Footer extends Component {
  render() {
    return (
        <footer class="footer-area footer--light">
        <div class="footer-big">
         
          <div class="container">
            <div class="row">
              <div class="col-md-3 col-sm-12">
                <div class="footer-widget">
                  <div class="widget-about">
                  <a href="">
                    <img src="img/logo-2.jpg" alt="" class="img-fluid"/>
                   </a> 
                    <div className="cname"><a href=""> DON TEX</a>
     </div>
                  </div>
                  
                </div>
                <p class="para"> <span class="hea">DON TEX</span> is a coordinated attire manufacturer with the Well versed capacity to plan ,design, and manufacture off the rack
articles of clothing.</p>
                  
              </div>
              <div class="col-md-3 col-sm-4">
                <div class="footer-widget">
                  <div class="footer-menu footer-menu--1">
                    <h4 class="footer-widget-title">LINKS</h4>
                    <ul>
                      
                      <li>
                        <a href="#gallerypage">GALLERY</a>
                      </li>
                      <li>
                        <a href="#about">ABOUT US</a>
                      </li>
                      <li>
                        <a href="#works">FACTORY</a>
                      </li>
                      <li>
                        <a href="#services">SERVICES</a>
                      </li>
                      <li>
                        <a href="#team">CODE OF CONDUCT</a>
                      </li>
                      <li>
                        <a href="#contact">CONTACT US</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
      
              <div class="col-md-3 col-sm-4">
                <div class="footer-widget">
                  <div class="footer-menu">
                    <h4 class="footer-widget-title">OUR PRODUCTS</h4>
                    <ul>
                      <li>
                        <a  href="#men" class=" esg-filterbutton esg-filter-wrapper  esg-fgc-2 " data-filter="men">MEN</a>
                      </li>
                      <li>
                        <a href="#women" class=" esg-filterbutton esg-filter-wrapper  esg-fgc-2" data-filter="women">WOMEN</a>
                      </li>
                      <li>
                        <a href="#boys" class=" esg-filterbutton esg-filter-wrapper  esg-fgc-2" data-filter="boys">BOYS</a>
                      </li>
                      <li>
                        <a href="#girls" class=" esg-filterbutton esg-filter-wrapper  esg-fgc-2" data-filter="girls">GIRLS</a>
                      </li>
                      
                    </ul> 
                  </div>
                </div>
              </div>
      
              <div class="col-md-3 col-sm-4">
                <div class="footer-widget">
                  <div class="footer-menu no-padding">
                    <h4 class="footer-widget-title">Help Support & Contact</h4>
                    <ul>
                      <li>
                        <p>We are very glad to take partnership with your production.Kindly contact us for more information.</p>
                    
                      </li>
                      <li>
                        <p class="cls"> <span class="hea">DON TEX</span> 8/24 F ANGERIPALAYAM ROAD OPP,KONGU VELLALAR SCHOOL TIRUPUR</p>

                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
      
            </div>
          </div>
        </div>
      
        <div class="mini-footer">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="copyright-text">
                  <p>© 2021
                    All rights reserved.
                    <a href="#header">DON TEX</a>
                  </p>
                </div>

<div class="desig">
                  <p class="siz">
                    Developed by 
                    <a href="tel:6381842845"> OVM TECH PARK SOLUTIONS</a>
                  </p>
                </div>
                
      
                <div class="go_top">
                  <span class="icon-arrow-up"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer ;
