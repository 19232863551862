import React, { Component } from "react";
import AnalogClock from 'r-analog-clock'


export class Header extends Component {
  render() {
    const mail1= {
    width: "40%",
    marginTtop: "42px",
    marginLleft: "-130px"
      
  };
  const phone1= {
    margintop: "-40px",
    marginleft: "383px"
      
  };
  const BROUCHER= {
    margintop: -"160px",
    height: "67px",
    marginright: "124px",
    float: "right"
      
  };
    return (
      <header id="header">
        <div className="intro">
          
 
     <div class="pull-left up">
     <div class="caption"> Mfrs & EXPORTERS OF KNITTED & WOVEN GARMENTS</div>
     
     <img href="#header" src="img/logo-1.jpg" class="logo_2"></img>
     
     <div href="#header" className="headings"> DON TEX
     </div>
     
     
     
     
     
     
     
     </div>
     
    </div>
    
      </header>
      
    );
  }
}

export default Header;
