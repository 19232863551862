import React, { Component } from "react";
import { Slide } from "react-slideshow-image";
import image1 from "./images/pexels-photo-6347888.jpeg";
import image2 from "./images/vector-t-shirt-designs-cover.png";
import image3 from "./images/HTB1vH2sXtHO8KJjSZFtq6AhfXXaR.jpg";
import image4 from "./images/man-cuts-layers-fabric-clothing-260nw-1722120523 (1).jpg";
import image5 from "./images/Garment_makers.jpg";
import "./style.css";
import "react-slideshow-image/dist/styles.css";

class App extends Component {
  constructor() {
    super();
    this.slideRef = React.createRef();
    this.back = this.back.bind(this);
    this.next = this.next.bind(this);
    this.state = {
      current: 0
    };
  }

  back() {
    this.slideRef.current.goBack();
  }

  next() {
    this.slideRef.current.goNext();
  }

  render() {
    const properties = {
      duration: 2000,
      autoplay: true,
      transitionDuration: 500,
      arrows: true,
      infinite: true,
      easing: "ease",
    indicators: (i) => <div className="indicator"></div>
    };
    const slideImages = [image1, image2, image3, image4, image5];
    return (
      <div className="App">
        
        
        <div id="slider" className="slide-container">
            
          <Slide ref={this.slideRef} {...properties}>
            {slideImages.map((each, index) => (
              <div key={index} className="each-slide">
                <img width="100%" height="420px" className="lazy" src={each} alt="sample" />
                
              </div>
              
            ))}
            
          </Slide>

          <br>
          </br>
          
        </div>

        {/*<div class="indicators">
    
        <button class="each-slideshow-indicator " data-key="0" aria-label="Go to slide 1"></button>
  
    
        <button class="each-slideshow-indicator active" data-key="1" aria-label="Go to slide 2"></button>
    
        <button class="each-slideshow-indicator active" data-key="2" aria-label="Go to slide 3"></button>
    
        <button class="each-slideshow-indicator " data-key="3" aria-label="Go to slide 4"></button>
    
        <button class="each-slideshow-indicator " data-key="4" aria-label="Go to slide 5"></button>
   
            </div>*/}
      </div>
    );
  }
}

export default App;
