import React, { Component } from 'react'

export class works extends Component {
  render() {
    const st1= {
     
      width:"100%"
  };
  const st2= {
    marginRight: "-60px;"
};

const fac1= {
  display:"block"
};
    return (
        <div  id="works" >
          
        <div style={st1}  className="container">
        <div className="section-title">
            <h2>FACTORY</h2>
          </div>
          <div className="row">
          
            <div className="col-xs-12 col-md-6"> 
            

            
            <button type="button" class="slide-toggle togbt btn btn-custom btn-lg faa-float animated faa-slow">TAP TO HIDE</button>
            
<div style={fac1} class="box">
  
        <div class="box-inner">
        <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/3-2.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac2-1">
                                <h3 itemprop="name" class="product_title entry-title">Knitting</h3>
                                <div itemprop="description">
                                    <p>Knitting at DONTEX is done by using some of the modern and state of the art machinery available in the market today. MAYER &amp;CIE, Terrot brand of machines form integral part of the knitting unit.</p>
                                </div>
                            </div>
                        </div>
                    </div>
        <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/IV-AA423_DYE_P_20130606172604.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac2-1">
                                <h3 itemprop="name" class="product_title entry-title">Dyeing</h3>
                                <div itemprop="description">
                                    <p>Houses advanced machinery from Germany as well as HTHP machines for polyester dyeing, winch dyeing, Soft flow machines, drying machines and rotary printing.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/KZ4A3359.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac2-1">
                                <h3 itemprop="name" class="product_title entry-title">Printing</h3>
                                <div itemprop="description">
                                    <p>Our garment spot printing division facilitates pigment and Plastisol prints on MHM machines from Taiwan and India. We are also capable of thermal transfer printing, flock printing and special prints.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
          <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/garment.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac2-1">
                                <h3 itemprop="name" class="product_title entry-title">Stitching</h3>
                                <div itemprop="description">
                                    <p>Garment sewing unit is done with proper lighting, good floor space and amenities. Legal and statutory norms with respect to social systems and physical facilities are provided.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/unnamed1.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac3-1">
                                <h3 itemprop="name" class="product_title entry-title">Checking</h3>
                                <div itemprop="description">
                                    <p>Inspection/Finishing Unit independent of production to ensure the Quality and Specifications of Buyers are met.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/ironing.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac2-1">
                                <h3 itemprop="name" class="product_title entry-title">Ironing</h3>
                                <div itemprop="description">
                                    <p>Stream Ironing the Finished products to packing quickly. Garments are steam processed to remove any wrinkles.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/unnamed (1).jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac2-1">
                                <h3 itemprop="name" class="product_title entry-title">Packing</h3>
                                <div itemprop="description">
                                    <p>As per customers instruction garments are neatly packing final for shipment. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    
    </div> 
    <img id="togimg" width="535" height="400" src="img/team/unnamed.jpg" class="attachment-shop_single size-shop_single scrimg" alt="product_18" title="product_18"/>
    </div>
            <div style={st2} className="col-xs-12 col-md-6" >
              <h2>INFRASTRUCTURE</h2>
                             <div class="welcom ul li">
                             <p>We invest wholeheartedly in presenting the name DONTEX a huge tree under which more than 400 and odd workers and their relatives flourish upon. It appeared in the year 2000, since the time advanced in size and height to maintain the image where it stands today. Halfway for us everything is a culture and an image of status. DONTEX a dream came into reality by long stretches of tolerance and continued sustained hardwork.</p>
      
      
      <h3>Knit Products - For Men, Women and Childeren</h3>
      <ul>
      <li>T - Shirts </li>
       <li>Polo Shirts </li>
       <li>Sweat Shirts</li>
        <li>Pyjamas</li>
         <li>Sleep Shirts </li>
          <li>Pants </li>
           <li>Capri Pants </li>
           <li>Boxer Shorts</li>
      </ul>
                             </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default works
