import React, { Component } from "react";

export class Factory2 extends Component {
  render() {
      
    return (
        <div id="factory2">
            
    <div class="master-container facbg fachigh">
            <div class="container">
            

                
 <div class="row ">
                    
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/garment.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac2-1">
                                <h1 itemprop="name" class="product_title entry-title">Stitching</h1>
                                <div itemprop="description">
                                    <p>Garment sewing unit is done with proper lighting, good floor space and amenities. Legal and statutory norms with respect to social systems and physical facilities are provided. We have 150 sewing machine powered and electronically controlled to sew quality garments to perfection, our production capacity is about 80,000pcs per month.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row ">
                    
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/unnamed.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac3-1">
                                <h1 itemprop="name" class="product_title entry-title">Checking</h1>
                                <div itemprop="description">
                                    <p>Inspection/Finishing Unit independent of production to ensure the Quality and Specifications of Buyers are met.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        
        </div>
        
        );
  }
}

export default Factory2;