import React, { Component } from "react";

export class Team extends Component {
  render() {
    return (
      <div id="team" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-2 section-title">
            <div class="hdng"> 
            <h2>CODE OF CONDUCT</h2>
            </div>
           
            <div class="cond">
            <p>
            <span class="txtt">Child Labour, Health and Safety : </span>We guarantee moral standards are kept up according to the global laws identified with kid work, constrained work, segregation, wellbeing and security, opportunity of affiliation, disciplinary work on, working hours, and pay at all assembling offices on our creation board.
            </p>
            </div>
          </div>
          <div id="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div  key={`${d.name}-${i}`} className="col-md-4 col-sm-6 team">
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..."  className="team-img" />
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
