import React, { Component } from "react";
import axios from 'axios'
import SimpleMap from './map';

export class Contact extends Component {
  constructor(props) {
		super(props)
  this.state = {
    name: '',
    email: '',
    message: ''
  }
}

changeHandler = e => {
  this.setState({ [e.target.name]: e.target.value })
}
  submitHandler = e => {
    e.preventDefault()
    var config = {
      headers: {'Access-Control-Allow-Origin': '*'}
  };
  
  axios({
    method: 'post',
    url:'http://localhost:8000/contact',
    headers: {}, 
    data: this.state
  });
   const name = this.state.name;
  this.setState({
    name: '',
    email:'',
    message:''
  });
  document.getElementById("contactForm").reset();
	}
  render() {
    const { name, email, message } = this.state
    return (
      
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-6">
              <div className="row">
                <div className="section-title">
                  <h2>Get In Touch</h2>
                  <p>
                    Please fill out the form below to send us an email and we
                    will get back to you as soon as possible.
                  </p>
                </div>
                <form name="sentMessage" onSubmit={this.submitHandler} id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                          onChange={this.changeHandler}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder="Email"
                          required="required"
                          onChange={this.changeHandler}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      onChange={this.changeHandler}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="contact-item">
                 
               
                 <div>
                 <div class="mapouter"><div class="gmap_canvas"><iframe class="mapp" width="600" height="320" id="gmap_canvas" src="https://maps.google.com/maps?q=dontex%20tirupur&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.whatismyip-address.com/nordvpn-coupon/"></a></div></div>
                 </div>
                  
    </div>
    </div>
          </div>
        </div>
        
        
      </div>
      
    );
  }
}

export default Contact;
