import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
const AnyReactComponent = ({ text }) => (
    <div style={{
      
    }}>
      {text}
    </div>
  );
  
  class SimpleMap extends Component {
    static defaultProps = {
      center: {lat:11.1350657, lng:77.3332745},
      zoom: 11
    };
    
  
    render() {
      return (
          
         <GoogleMapReact
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent 
            lat={11.1350657} 
            lng={77.3332745} 
            text={'DON TEX'} 
          />
        </GoogleMapReact>
      );
    }
  }
  
  export default SimpleMap;
  