import React, { Component } from "react";
import ScriptTag from 'react-script-tag';



export class Gallery extends Component {
	
      
  render() {
    const esggridinline= {
        backgroundColor: "transparent",
        padding: "0px 0px 30px 0px",
        boxSizing: "border-box",
        mozBoxSizing: "border-box",
        webkitBoxSizing: "border-box",
        display: "none"
    };
    const s2= {
        marginBottom:"30px",
         textAlign: "left"
    };
    const s3= {

        
        marginRight: "0px",
        marginTop:"0px",
        borderRadius: "7px",
        marginLeft: "202px"
    
    };
    const navgall= {
        width:" 100%",
        
    };
    const clsbtn= {
        backgroundCcolor: "transparent",
         border: "0px",
          display: "none"
    };
    const nextbtn= {
        display:"none"
    }
    const nav={
        marginTop:"65px",
        width: "100%",
       
    }
const gaimg={
    width: "250px !important",
    height: "300px !important"
}
    

   
    
    return (
        
        <div  id="gallerypage" className="container facbg" style={nav}>
            
            
      <div className="text-center">
          
        <article class="myportfolio-container buildpress-light" id="esg-grid-2-1-wrap">

<div id="esg-grid-2-1" class="esg-grid" style={esggridinline,navgall}>
    
    <article class="esg-filters esg-singlefilters" style={s2} >
        <div  class="esg-filter-wrapper  esg-fgc-2" style={s3}>
          <div  id="men" className=" page-scroll faa-parent animated-hover" class=" esg-filterbutton selected" data-filter="men">
                <span>MEN</span>
                <span class="esg-filter-checked">
                    <i class="eg-icon-ok-1"></i>
                </span>
            </div>
        <div id="women" class="esg-filterbutton " data-filter="women">
                <span>WOMEN</span>
                <span class="esg-filter-checked ">
                    <i class="eg-icon-ok-1"></i>
                </span>
            </div>
         <div id="boys" class="esg-filterbutton " data-filter="boys">
                <span>BOYS</span>
                <span class="esg-filter-checked">
                    <i class="eg-icon-ok-1"></i>
                </span>
            </div>
             <div id="girls" class="esg-filterbutton " data-filter="girls">
                <span>GIRLS</span>
                <span class="esg-filter-checked">
                    <i class="eg-icon-ok-1"></i>
                </span>
            </div>
            <div class="eg-clearfix"></div>
        </div>
    </article>

    <div class="esg-clear-no-height"></div>
    <ul id="gallery">
          <li  style={gaimg} class="men eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media">
                    <img src="img/team/b67159e3c8d3ca76cdd075ce42f20279.jpg" alt="Project Image"/>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">MEN</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/b67159e3c8d3ca76cdd075ce42f20279.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            </div>
            
</li>

<li style={gaimg} class="men eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/81oP2Ka8loL._UX569_.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">MEN</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/81oP2Ka8loL._UX569_.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>
<li style={gaimg} class="men eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/eng_pl_Majors-sweatshirt-hoody-Caution-HD-yellow-5744_1.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">MEN</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/eng_pl_Majors-sweatshirt-hoody-Caution-HD-yellow-5744_1.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>
<li style={gaimg} class="men eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/mens-collarless-t-shirt-500x500-500x500.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">MEN</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/mens-collarless-t-shirt-500x500-500x500.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>

<li style={gaimg} class="women eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/08016686250e71e4614a36b00d0459a2.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">WOMEN</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/08016686250e71e4614a36b00d0459a2.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>
<li style={gaimg} class="women eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/ladies-fancy-t-shirt-500x500.png" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">WOMEN</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/ladies-fancy-t-shirt-500x500.png" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>
<li style={gaimg} class="women eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/81EHBScRA-L._AC_UX569_.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">WOMEN</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/sloth-spirit-animal-womens-ultra-slim-t-shirt-teeturtle-teeturtle-1000x1000.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>
<li style={gaimg} class="women eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/v-necked-t-shirt-500x500.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">WOMEN</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/v-necked-t-shirt-500x500.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>     
<li style={gaimg} class="boys eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/61+DRCco1fL._SX679_.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">BOYS</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/61+DRCco1fL._SX679_.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>             
<li style={gaimg} class="boys eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/817JsKb14KL._AC_UL1500_.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">BOYS</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/817JsKb14KL._AC_UL1500_.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>             
<li style={gaimg} class="boys eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/ffb533b8a5dcdce81ba28b66c1669414.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">BOYS</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/818QelHDKVL._UL1500_.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>             
<li style={gaimg} class="boys eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/71PxxK2bF0L._UX569_.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">BOYS</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/71PxxK2bF0L._UX569_.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>  
<li style={gaimg} class="girls eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/030b94fb-6053-4265-80a5-66d2d57809f91607938882630-1 (1).jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">GIRLS</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/030b94fb-6053-4265-80a5-66d2d57809f91607938882630-1 (1).jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>    
<li style={gaimg} class="girls eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/SF-INSTANTTEACHER-500x500_500x.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">GIRLS</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/SF-INSTANTTEACHER-500x500_500x.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>  
<li style={gaimg} class="girls eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/good-girls-go-to-heaven-bad-girls--kids-hoodie-light-pink.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">GIRLS</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/pmpl_164879_1.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>  
<li style={gaimg} class="girls eg-buildpress-item-skin-wrapper eg-post-id-225" data-date="1414421567">
          <div class="esg-media-cover-wrapper">
                <div class="esg-entry-media"><img src="img/team/prod_17544158119.jpg" alt="Project Image"/></div>
                <div class="esg-entry-cover">
                    <div class="esg-overlay esg-fade eg-buildpress-item-skin-container" data-delay="0"></div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-1 esg-flipdown" data-delay="0">GIRLS</div>
                    <div class="esg-center eg-post-225 eg-buildpress-item-skin-element-0-a esg-slideup" data-delay="0">
                        <a class="eg-buildpress-item-skin-element-0 eg-post-225" href="img/team/prod_17544158119.jpg" target="_self">ZOOM</a>
                    </div>
                </div>
            </div>
            
</li>                                                   
                                                        </ul>
    
    <div class="esg-clear-no-height"></div>
</div>

</article>
<div id="pbOverlay">
										<input type="checkbox" id="pbThumbsToggler" checked="" hidden=""/>
										<div class="pbLoader">
										<b></b><b></b><b></b>
										</div>
										<div id="pbPrevBtn" class="prevNext pbHide">
										<b></b>
										</div>
										<div id="pbNextBtn" class="prevNext">
										<b></b>
										</div>
										<div class="pbWrapper">
										
										<div class="pbVideo">
										</div>
										</div>
										<div id="pbCloseBtn">
										</div>
										<div id="pbAutoplayBtn">
										<div class="pbProgress">
										</div>
										</div><div id="pbCaption">
                                            
                                            <button title="Rotate right" class="rotateBtn show">⟳</button>
                                           
                                               
                                                    </div>
                                                    </div>
                                       
                                        
      </div>
      </div>
      

      
    );
  }
  
}

export default Gallery;
