import React, { Component } from "react";

export class Factory1 extends Component {
  render() {
    return (
        <div id="factory1">
    <div class="master-container ">
            <div class="container">
                <div class="row">
                    
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/3-2.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac1-1">
                                <h1 itemprop="name" class="product_title entry-title">Knitting</h1>
                                <div itemprop="description">
                                    <p>Knitting at  is done by using some of the modern and state of the art machinery available in the market today. MAYER &amp;CIE, Terrot brand of machines form integral part of the knitting unit.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/IV-AA423_DYE_P_20130606172604.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac1-2">
                                <h1 itemprop="name" class="product_title entry-title">Dyeing</h1>
                                <div itemprop="description">
                                    <p>This unit, situated at Erode, houses advanced machinery from Thies, Germany as well as HTHP machines for polyester dyeing, winch dyeing, Soft flow machines, drying machines and rotary printing. Our Reverse Osmosis feed tank plant recycles discharged water so as to make it suitable for irrigation, thereby testifying to our eco-friendly operations.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    
                    <div class="col-xs-12" role="main">
                        <div itemscope="" itemtype="http://schema.org/Product" id="product-402" class="post-402 product type-product status-publish has-post-thumbnail product_cat-hand-tools first instock featured downloadable shipping-taxable purchasable product-type-simple">
                            <div class="images">
                                <a href="" itemprop="image" class="woocommerce-main-image zoom" title="" data-rel="prettyPhoto">
                                    <img width="535" height="400" src="img/team/KZ4A3359.jpg" class="attachment-shop_single size-shop_single wp-post-image" alt="product_18" title="product_18"/>
                                </a>
                            </div>
                            <div class="summary entry-summary fac2-1">
                                <h1 itemprop="name" class="product_title entry-title">Printing</h1>
                                <div itemprop="description">
                                    <p>Our garment spot printing division facilitates pigment and Plastisol prints on MHM machines from Taiwan and India. We are also capable of thermal transfer printing, flock printing and specialized prints using latest print techniques as Sublimation Printing and Digital Printing. Our printing machines are capable of creating trendy and delicate designs and it is one of our biggest fortes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                
                 
                 

            </div>
        </div>
        </div>
        );
  }
}

export default Factory1;