import React, { Component } from "react";

export class Factory4 extends Component {
  render() {
    const fac1= {
      display:"none"
  };
    return (
      
    <div >
<button type="button" class="slide-toggle">Slide Toggle</button>
<div style={fac1} class="box">
        <div class="box-inner">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eu sem tempor, varius quam at, luctus dui. Mauris magna metus, dapibus nec turpis vel, semper malesuada ante. Vestibulum id metus ac nisl bibendum scelerisque non non purus. Suspendisse varius nibh non aliquet sagittis. In tincidunt orci sit amet elementum vestibulum. Vivamus fermentum in arcu in aliquam. Quisque aliquam porta odio in fringilla. Vivamus nisl leo, blandit at bibendum eu, tristique eget risus. Integer aliquet quam ut elit suscipit, id interdum neque porttitor.</div>
    </div>
</div>

        );
  }
}

export default Factory4;